
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbTypeahead, NgbTypeaheadSelectItemEvent, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { ActivityApiService } from 'src/app/core/api/activity-api.service';
import { AdminApiService } from 'src/app/core/api/admin-api.service';
import { Activity } from 'src/app/core/models/activity';
import { ActivityType } from 'src/app/core/models/activity-type';
import { Admin } from 'src/app/core/models/admin';
import { ToastrService } from 'ngx-toastr';
import { convertToDate } from 'src/app/core/Util/util';
import { ProfileApiService } from 'src/app/core/api/profile-api.service';
import { MiniProfile } from 'src/app/core/models/mini-profile';
import { ProfilePersonalData } from 'src/app/core/models/profile-personal-data';
import { TodoTasksDto } from 'src/app/dashboard/components/todo-list-full/models/todo-task-dto'
import { TodoTaskStatusEnum } from 'src/app/core/models/enum/todo-task-status-enum';
import { TranslatePipe } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';


@Component({
    selector: 'app-activities-add-modal',
    templateUrl: './activities-add-modal.component.html',
    styleUrls: ['./activities-add-modal.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgbTypeahead, NgbInputDatepicker, AsyncPipe, TranslatePipe]
})
export class ActivitiesAddModalComponent implements OnInit {
  @Input() profile: ProfilePersonalData;

  public $activityTypes: Observable<ActivityType[]>

  public activityForm: UntypedFormGroup;
  public todoTask: TodoTasksDto = new TodoTasksDto();
  public adminId: number;
  public performActivityNow: boolean = false;
  public foundProfile: ProfilePersonalData = new ProfilePersonalData();

  constructor(
    private activeModal: NgbActiveModal,
    private activityApiService: ActivityApiService,
    private formBuilder: UntypedFormBuilder,
    private adminApiService: AdminApiService,
    private toastr: ToastrService,
    private profileApiService: ProfileApiService
  ) { }

  ngOnInit() {
    this.todoTask.activity = new Activity();
    if (this.profile == null || this.profile == undefined) {
      this.profile = new ProfilePersonalData();
    }
    this.buildForm();
    this.$activityTypes = this.activityApiService.getActivityTypes();
  }

  public adminFormatter = (admin: Admin) => admin.name;
  public profileFormatter = (profile: MiniProfile) => profile.fullName;

  public adminResult = (text: Observable<string>) =>
    text.pipe(
      debounceTime(150),
      distinctUntilChanged(),
      switchMap(searchText =>
        this.adminApiService.search(searchText).pipe(
          map(values => values)
        )
      )
    )

  public profileResult = (text: Observable<string>) =>
    text.pipe(
      debounceTime(150),
      distinctUntilChanged(),
      switchMap(searchText =>
        this.profileApiService.searchProfiles(searchText).pipe(
          map(values => values)
        )
      )
    )

  public onAdminFieldChanged(event: NgbTypeaheadSelectItemEvent) {
    const admin: Admin = event.item;
    this.adminId = admin.adminId;
  }

  public onProfileFieldchanged(event: NgbTypeaheadSelectItemEvent) {
    const profile: MiniProfile = event.item;
  }

  public close() {
    this.activeModal.close();
  }

  private buildForm() {
    this.activityForm = this.formBuilder.group({
      activityTypeId: [this.todoTask.id, Validators.required],
      deadline: [this.todoTask.deadline],
      status: [this.todoTask.taskStatus === TodoTaskStatusEnum.NotCompleted, Validators.required],
      profileId: [this.profile.id == null ? this.foundProfile.id : this.profile.id, Validators.required],
      note: [this.todoTask.activity.note, Validators.required],
      adminId: [this.todoTask.adminId, TodoTaskStatusEnum.NotCompleted ? Validators.required : null],
    });
  }

  public onSelectChange(event) {
    if (event.target.value == "0: null") {
      event.target.classList.add("with-placeholder");
      event.target.classList.remove("without-placeholder");
    } else {
      event.target.classList.add("without-placeholder");
      event.target.classList.remove("with-placeholder");
    }
  }

  public changeStatus(e) {
    if (e.target.id === "now") {
      this.performActivityNow = true;
      this.activityForm.controls["adminId"].setValidators(null);
    } else {
      this.performActivityNow = false;
      this.activityForm.controls["adminId"].setValidators(Validators.required);
    }
  }

  public save() {
    if (this.activityForm.invalid) {
      this.toastr.error("Ikke alle er udfyldt korrekt");
      return;
    }
    let attachmentDate = convertToDate(this.activityForm.value.deadline);
    this.assignData();
    this.todoTask.adminId = this.adminId;
    this.todoTask.deadline = attachmentDate;
    this.todoTask.createdDate = new Date();
    this.activityApiService.postActivity(this.todoTask).subscribe(result => {
      this.activeModal.close(result);
      this.toastr.success("Aktivitet er blevet oprettet.");
    })
  }

  private assignData() {
    this.todoTask.activity.note = this.activityForm.get('note').value;
    let profileControl = this.activityForm.get('profileId').value;
    this.todoTask.profileId = profileControl.id == null || undefined ? profileControl : profileControl.id ;
    this.todoTask.adminId = this.activityForm.get('adminId').value;
    let activityTypeIdString = this.activityForm.get('activityTypeId').value;
    this.todoTask.activity.activityTypeId = Number.parseInt(activityTypeIdString);
    if (this.activityForm.get('status').value) {
      this.todoTask.taskStatus = TodoTaskStatusEnum.Completed
    }
    else {
      this.todoTask.taskStatus = TodoTaskStatusEnum.NotCompleted
    }
  }
}
